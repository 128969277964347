/*--------------------------Navigation menu FrontEnd--------------------------*/
.hamburger {
  display: block;
  position: fixed;
  top: 20px;
  left: 6px;
  width: 40px;
  height: 14px;
  transform: rotate(0deg);
  transition: transform .5s ease-in-out;
  cursor: pointer;
  z-index: 99999;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: darken($primary-color, 15);
    border-radius: 0px;
    opacity: 1;
    left: 0; }

  span:nth-child(1) {
    top: 0px;
    transform-origin: 50%, 50%;
    transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }


  span:nth-child(2) {
    top: 7px;
    width: 100%;
    margin: 0px 0% 0px;
    transition: background-color 350ms ease 600ms; }

  span:nth-child(3) {
    top: 14px;
    transform-origin: 50%, 50%;
    transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }

  &.open {
    span {
      background-color: $primary-color; }

    span:nth-child(1) {
      transform: rotate(45deg);
      top: 7px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; }

    span:nth-child(2) {
      width: 0%;
      margin: 0px 50% 0px;
      transition: width 250ms ease-in-out, margin 250ms ease-in-out, background-color 250ms ease 500ms; }

    span:nth-child(3) {
      transform: rotate(-45deg);
      top: 7px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; } } }

#logo-trigger {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 45px;
  cursor: pointer;
  z-index: 100; }

header.menus {
  .front-page-menu {
    position: fixed;
    width: 350px;
    top: 0;
    bottom: 0;
    z-index: 100;
    transform: translateX(-100%);
    pointer-events: none;
    transition: transform 400ms cubic-bezier(0.91, 0.01, 0.6, 1);
    background-color: darken($primary-color, 25);
    //@include cardBoxShadow ($cardBoxShadowColor)

    .menu {
      padding: 3.5em 0 0 0;
      list-style-type: none;

      ul {
        margin-left: 0; }

      li {
        padding: 0.75rem 0.45rem;
        font-size: 1.25em;
        text-transform: uppercase;
        font-weight: lighter;
        list-style: none;
        color: $primary-color;
        transition: color 250ms;
        border-bottom: 1px solid darken($primary-color, 20);

        i.mdi {
          margin-right: 8px; }

        a {
          display: inline-block;
          color: $white;
          transition: transform 250ms, color 250ms; }

        &:hover {
          font-weight: bold;

          a {
            color: darken($primary-color, 5);
            transform: translateX(4px); } } } } }

  .front-page-topbar {
    position: fixed;
    width: 100%;
    top: 0;
    padding-left: {}
    z-index: 101;
    transform: translateY(-100%);
    pointer-events: none;
    transition: transform 400ms cubic-bezier(0.91, 0.01, 0.6, 1);
    background-color: darken($primary-color, 25);
    border-bottom: 1px solid darken($primary-color, 20);

    .menu {
      padding: 0 0 0 3.5em;
      list-style-type: none;

      ul {
        margin: 0; }

      li {
        display: inline-block;
        padding: 0.75rem 0.45rem;
        text-transform: uppercase;
        font-weight: lighter;
        list-style: none;
        color: $primary-color;
        transition: color 250ms;
        border-left: 1px solid darken($primary-color, 20);
        will-change: sad;

        &:nth-child(1) {
          width: 288px; }

        i.mdi {
          margin-right: 8px; }

        a {
          display: inline-block;
          color: $white;
          transition: transform 250ms, color 250ms; }

        &:hover {
          font-weight: bold;

          a {
            color: darken($primary-color, 5);
            transform: translateX(4px); } } } }


    .user-picture {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      vertical-align: middle;
      margin-right: 5px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: 1px solid $white; } }


  &.visible {
    .front-page-menu {
      transform: translateX(0);
      pointer-events: auto;
      transition: transform 400ms cubic-bezier(0.91, 0.01, 0.6, 1); }

    .front-page-topbar {
      transform: translateY(0);
      pointer-events: auto;
      transition: transform 400ms cubic-bezier(0.91, 0.01, 0.6, 1); } } }

//THEME COLORS
body.company,
body.admin,
body.superadmin {}
.hamburger {
  span {
    background-color: darken($secondary-color, 15); } }
