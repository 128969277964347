// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group grid
////

/// Calculates the width of a column based on a number of factors.
///
/// @param {Number|List} $columns
///   Width of the column. Accepts multiple values:
///   - A percentage value will make the column that exact size.
///   - A single digit will make the column span that number of columns wide, taking into account the column count of the parent row.
///   - A string of the format "x of y" will make a column that is *x* columns wide, assuming *y* total columns for the parent.
///
/// @returns {Number} A calculated percentage value.
@function grid-column($columns) {
  $width: 0%;

  // Parsing percents, decimals, and column counts
  @if type-of($columns) == 'number' {
    @if unit($columns) == '%' {
      $width: $columns;
    }
    @else if $columns < 1 {
      $width: percentage($columns);
    }
    @else {
      $width: percentage($columns / $grid-column-count);
    }
  }

  // Parsing "n of n" expressions
  @else if type-of($columns) == 'list' {
    @if length($columns) != 3 {
      @error 'Wrong syntax for grid-column(). Use the format "n of n".';
    }
    @else {
      $width: percentage(nth($columns, 1) / nth($columns, 3));
    }
  }

  // Anything else is incorrect
  @else {
    @error 'Wrong syntax for grid-column(). Use a number, decimal, percentage, or "n of n".';
  }

  @return $width;
}

/// Creates a grid column.
///
/// @param {Mixed} $columns [$grid-column-count] - Width of the column. Refer to the `grid-column()` function to see possible values.
/// @param {Number} $gutter [$grid-column-gutter] - Spacing between columns.
@mixin grid-column(
  $columns: $grid-column-count,
  $gutter: $grid-column-gutter
) {
  $gutter: rem-calc($gutter) / 2;

  @include grid-column-size($columns);
  float: $global-left;
  padding-left: $gutter;
  padding-right: $gutter;

  @if $grid-column-align-edge {
    &:last-child:not(:first-child) {
      float: $global-right;
    }
  }
}

/// Creates a grid column row. This is the equivalent of adding `.row` and `.column` to the same element.
///
/// @param {Number} $gutter [$grid-column-gutter] - Width of the gutters on either side of the column row.
@mixin grid-column-row(
  $gutter: $grid-column-gutter
) {
  @include grid-row;
  @include grid-column($gutter: $gutter);
  
  &,
  &:last-child {
    float: none;
  }
}

/// Shorthand for `grid-column()`.
/// @alias grid-column
@function grid-col(
  $columns: $grid-column-count
) {
  @return grid-column($columns);
}

/// Shorthand for `grid-column()`.
/// @alias grid-column
@mixin grid-col(
  $columns: $grid-column-count,
  $gutter: $grid-column-gutter
) {
  @include grid-column($columns, $gutter);
}

/// Shorthand for `grid-column-row()`.
/// @alias grid-column-row
@mixin grid-col-row(
  $gutter: $grid-column-gutter
) {
  @include grid-column-row($gutter);
}
