.topbar {
  padding: 0 1em;

  .menu {
    .leaf {
      a {
        width: auto; }

      &:hover {
        a {
          width: auto; } } } } }
