// Align footer to the bottom with fullpagejs
.footer__fp {
  .fp-tableCell {
    vertical-align: bottom; } }

footer {
  display: block;
  position: relative;
  width: 100%;
  background-color: $primary-color;

  .footer__logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 25%;
    padding: 8em 0;
    margin-top: 0.4em;
    background-color: $white;
    text-align: center;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $white;
      transform: skew(-18deg);
      transform-origin: bottom; }

    img {
      position: relative;
      margin: auto;
      max-height: 200px; } }

  .footer__content {
    display: inline-block;
    vertical-align: middle;
    width: 74%;
    padding-left: 8em;
    text-align: left; }

  span {
    display: block;
    margin: 1em 0;
    max-width: 250px;
    color: $white; }

  form {
    display: block;
    position: relative;
    margin: 0.5em 0;
    width: 350px;
    max-width: 85%;

    input[type=text] {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: $white;
      height: 50px;
      padding-right: 48px;
      border-bottom: 1px solid $white !important;

      &:hover {
        outline: none;
        box-shadow: none; }

      &:focus {
        border-bottom: 2px solid $white;

        &::placeholder {
          opacity: 0.5;
          transition: opacity 0.35ms ease-in-out; } }

      &::placeholder {
        color: $white;
        opacity: 1;
        transition: opacity 0.35ms ease-in-out; } }


    input[type=button] {
      position: absolute;
      top: 8px;
      right: 8px;
      border: none;
      width: 32px;
      height: 32px;
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('../img/icons/homepage/arrow.svg');
      transform: rotate(-90deg); } }

  .sitemap {
    display: block;
    width: 100%;
    margin: 0.5em 0;
    color: $white;

    li {
      display: inline;

      &:after {
        content: '|';
        display: inline;
        margin: 0 2px 0 7px; }

      &:last-child:after {
        content: normal; }

      a {
        color: $white !important; } } }

  .social {
    i {
      display: inline-block;
      margin: 0 0.35em;
      padding: 0.4em;
      border-radius: 50%;
      background: $white;
      color: $primary-color; } }

  .di {
    width: 145px;
    padding: 1.5em 0em;
    margin: auto;

    span {
      display: block;
      float: right;
      font-size: 14px;
      color: $white;
      font-weight: 300;
      margin: 0 0 3px 0; }

    img {
      display: block;
      float: right;
      margin: 0; }

    &.border {
      border-top: 5px solid $primary-color; } } }
