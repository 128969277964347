////
/// @group accordion-menu
////

/// Sets if accordion menus have the default arrow styles.
/// @type Boolean
$accordionmenu-arrows: true !default;

@mixin foundation-accordion-menu {
  @if $accordionmenu-arrows {
    .is-accordion-submenu-parent > a {
      position: relative;

      &::after {
        @include css-triangle(6px, $primary-color, down);
        position: absolute;
        top: 50%;
        margin-top: -4px;
        right: 1rem;
      }
    }

    .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
      transform-origin: 50% 50%;
      transform: scaleY(-1);
    }
  }
}
