//-----------------------Start First Section------------------------------------
.fsimage {
    min-height: 80vh; }

.intro {
  .center-content {
    .logo {
      display: block;
      width: auto;
      width: 200px;
      margin-bottom: 2em; } } }

//------------------------------How it works------------------------------------
.how-it-works {}

