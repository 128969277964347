/*-----------------------------------Benefits---------------------------------*/
section.benefits {
  .content, .selectors {
    display: block;
    position: relative;
    width: 50%;
    height: 350px;
    float: right;
    font-size: 1.5em; }

  .section-title {
    margin-bottom: 5em; }

  .icon {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0 0 4em 30px;
    top: auto; }

  //Theme content items
  .content .item {
    text-align: left;
    min-width: auto;
    width: 100%;
    transform: translateX(-40%); } }
