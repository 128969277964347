.pagination {
    display: block;
    text-align: center;

    .disabled {
        padding: 0; }

    li {
        display: inline-block;
        opacity: 0.75;

        span {
            color: $pagination-item-color;
            display: block;
            padding: $pagination-item-padding;
            border-radius: $global-radius;

            &:hover {
                background: $pagination-item-background-hover; } }

        &.active {
            font-weight: bold;
            opacity: 1; } } }

body.client,
body.not-logged-in {
    .pagination {
        li {
            a, span {
                color: $primary-color; } } } }

body.company,
body.admin,
body.superadmin {
    .pagination {
        li {
            a, span {
                color: $secondary-color; } } } }
