table {
  width: calc(100% - 1em);
  margin: 0.5em;

  td {
    padding: 0.7rem; }

  thead {
    font-weight: bold; }

  tbody {
    tr:nth-child(even) {
      background-color: rgba($white, 0.05); } } }

/*--------------------Themes specific color of the tables---------------------*/
body.client {
  table {
    color: $black;

    thead {
      border-bottom: 2px solid $primary-color;

      tr {
        background-color: $primary-color !important;
        color: $white;
        text-transform: uppercase;
        font-weight: 200; } } } }

body.company,
body.admin,
body.superadmin {
  table {
    color: $white;

    thead {
      border-bottom: 2px solid $secondary-color;

      tr {
        background-color: $secondary-color !important;
        color: $black;
        text-transform: uppercase;
        font-weight: lighter; } } } }
