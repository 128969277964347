@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.5.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.5.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg'); }

.notification {
  display: block;
  position: fixed;
  padding: 1.5em 2.5em 1.5em 6em;
  max-width: 700px;
  bottom: 15px;
  right: 0px;
  font-weight: lighter;
  color: $white;
  z-index: 3;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 450ms ease, opacity 400ms ease;

  &.modal {
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%,-50%);
    transition: transform 0ms ease, opacity 400ms ease;

    &.show {
      transform: translate(-50%,-50%);
      transition: transform 0ms ease 0ms, opacity 300ms 150ms; } }

  &:hover {
    .close {
      opacity: 1; } }

  &.show {
    transform: translateX(0);
    opacity: 1;
    transition: transform 450ms ease 100ms, opacity 0ms; }

  .close {
    position: absolute;
    display: block;
    top: 0.2em;
    right: 0.4em;
    color: $white;
    opacity: 0.2;
    transition: opacity 300ms ease;

    &:before {
      font-family: 'FontAwesome';
      content: "\f00d"; } }

  .btn {
    position: relative;
    margin: 15px 5px -10px;
    background-color: rgba(black, 0.25);
    box-shadow: 0 3px rgba(black, 0.4);
    border:none {}
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    outline: none;
    cursor: pointer;

    &:hover {
      background: rgba(black, 0.3); }

    &:active {
      background: rgba(black, 0.3);
      box-shadow: 0 0px rgba(black, 0.4);
      top: 3px; } }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 4.5em;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba($black, 0.2); }

  &:after {
    font-family: 'FontAwesome';
    position: absolute;
    display: block;
    top: 50%;
    left: 2.25em;
    transform: translate(-50%, -50%); }

  &.success {
    background-color: $success-color;

    &:after {
      content: "\f00c"; } }

  &.warning {
    background-color: $warning-color;

    &:after {
      content: "\f12a"; } }

  &.alert {
    background-color: $alert-color;

    &:after {
      content: "\f00d"; } }

  &.info {
    background-color: $info-color;

    &:after {
      content: "\f05a"; } } }
