/*-------------------------------- Buttons -----------------------------------*/
.button {
  padding: 0.55em 2.5em;
  margin: 1em 0;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 16px;
  transition: all 350ms ease;

  &.tiny {
    font-size: 0.6em; }

  &.small {
    font-size: 0.8em; }

  &:hover {
    text-decoration: none; }

  &.secondary {
    font-weight: 300; }

  &.success {
    background-color: $success-color !important;
    border: 2px solid $success-color !important;
    font-weight: 300;

    &:hover {
      background-color: transparent !important;
      color: $success-color !important; } }

  &.warning {
    background-color: $warning-color !important;
    border: 2px solid $warning-color !important;
    font-weight: 300;

    &:hover {
      background-color: transparent !important;
      color: $warning-color !important; } }

  &.alert {
    background-color: $alert-color !important;
    border: 2px solid $alert-color !important;
    font-weight: 300;

    &:hover {
      background-color: transparent !important;
      color: $alert-color !important; } } }

/*---------------------------------Add button---------------------------------*/
.add {
  display: inline-block;
  text-align: center;

  &:hover {
    .plus {
      transform: rotate(180deg); } }

  .plus {
    display: block;
    position: relative;
    width: 9em;
    height: 9em;
    margin-bottom: 10px;
    border-radius: 50%;
    transform: rotate(0deg);
    transition: all 250ms ease;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.25em;
      margin-left: -2em;
      width: 4em;
      height: 0.5em;
      border-radius: 4px; }

    &:before {
      transform: rotate(-90deg); } } }

// Action buttons
.actions {
  display: block;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 99;

  .button {
    padding: 0.5rem;
    font-size: 1.2em;
    border-radius: 50%; } }

/*------------------Changes icon color on permissions context------------------*/
body.client,
body.not-logged-in,
body.logged-in.frontpage {
  .universe {
    .button {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: $white;

      &:hover {
        color: $primary-color;
        background-color: transparent; }

      &.secondary {
        background-color: transparent;
        color: $primary-color;

        &:hover {
          background-color: $primary-color;
          color: $white; } }

      &.mono {
        border: 2px solid $medium-gray;
        color: $medium-gray;
        background-color: $white;

        &:hover {
          border: 2px solid transparent;
          color: $white;
          background-color: transparent; }

        &.secondary {
          border: 2px solid $white;
          color: $white;
          background-color: transparent;

          &:hover {
            border: 2px solid $white;
            color: $dark-gray;
            background-color: $white; } } }

      &.hollow {
        border: 2px solid transparent;
        background-color: transparent;
        color: darken($primary-color, 15);

        &:hover {
          background-color: rgba($black, 0.1);
          border: 2px solid transparent;
          color: $primary-color; } } }

    .add {
      color: $primary-color;

      .plus {
        background-color: $primary-color;

        &:before,
        &:after {
          background-color: $white; } } } } }

body.company,
body.admin,
body.superadmin {
  .universe {
    .button {
      border: 2px solid $secondary-color;
      background-color: $secondary-color;
      color: $medium-gray;

      &:hover {
        color: $secondary-color;
        background-color: transparent; }

      &.secondary {
        background-color: transparent;
        color: $secondary-color;

        &:hover {
          background-color: $secondary-color;
          color: $medium-gray; } }

      &.mono {
        border: 2px solid $medium-gray;
        color: $medium-gray;
        background-color: $white;

        &:hover {
          border: 2px solid transparent;
          color: $white;
          background-color: transparent; }

        &.secondary {
          border: 2px solid $white;
          color: $white;
          background-color: transparent;

          &:hover {
            border: 2px solid $white;
            color: $dark-gray;
            background-color: $white; } } }

      &.hollow {
        border: 2px solid transparent;
        background-color: transparent;
        color: $white;

        &:hover {
          background-color: rgba($black, 0.1);
          border: 2px solid transparent;
          color: $secondary-color; } } }

    .add {
      color: $white;

      .plus {
        background-color: $secondary-color;

        &:before,
        &:after {
          background-color: $dark-gray; } } } } }
