/*------------------Changes icon color on permissions context------------------*/
body.client,
body.not-logged-in {
  .tabs-title {
    a {
      color: $light-gray;
      transition: all 250ms linear; }

    > a:focus, a:hover, a[aria-selected='true'] {
      color: $primary-color; } } }

body.company,
body.admin,
body.superadmin {
  .tabs-title {
    a {
      color: $white;
      transition: all 250ms linear; }

    > a:focus, a:hover, a[aria-selected='true'] {
      color: $secondary-color;
      background-color: rgba($white, 0.15); } } }
