/*--------------------------Navigation menu FrontEnd--------------------------*/
.trigger {
  top: -104vmax;
  left: -104vmax;

  &.js-open {
    transform: rotate(45deg) translate(50vmax, 0vmax); } }

#logo-trigger {
  top: 20px;
  left: 20px; }
