div.icon {
  display: block;
  width: 8em;
  height: 8em;
  position: relative;
  padding: 1em;
  margin: 0.5em auto;
  font-size: 18px;

  img {
    position: absolute;
    max-width: 4em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  &.round {
    border-radius: 50%; }

  &.big {
    font-size: 26px; }

  &.tiny {
    font-size: 12px; }

  &.mono {
    border: 3px solid $white !important; }

  &.special {
    background-color: darken($primary-color, 5);
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(22%, -18%);
      background-color: $primary-color;
      border-radius: 50%;
      transition: transform 200ms ease; }

    &:hover {
        &:before {
          transform: translate(0, 0); } } }

  &.trip {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 50%;
      animation: beat 5000ms ease infinite;

      @keyframes beat {
        0% {
          transform: scale(1); }
        5% {
          transform: scale(0.995); }
        10% {
          transform: scale(1.015); }
        15% {
          transform: scale(1); } } } } }

$iconListClients: bell support emptylegs reverseauction proposals fleet registration statistics list bus airplaneside airplanemodel arrivalcity arrivalcitywhite calendar catering cateringwhite check ckecked clock email inbox login mail numberpeople password phone roundtrip roundtripwhite skype user;

i.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: $menu-icon-spacing;

  &+span {
    display: inline-block;
    vertical-align: middle; } }

/*------------------Changes icon color on permissions context------------------*/
body.client,
body.not-logged-in,
body.logged-in.frontpage {
  div.icon {
    border: 3px solid $primary-color;

    &.special {
      border: 0px none; }

    &.trip {
      &:after {
        border: 3px solid $primary-color; } } }

  @each $icon in $iconListClients {
    i.#{$icon} {
      background-image: url('../img/icons/dashboard-clients/#{$icon}.svg'); } } }

body.company,
body.admin,
body.superadmin {
  div.icon {
    border: 3px solid $secondary-color;

    &.special {
      border: 0px none; }

    &.trip {
      &:after {
        border: 3px solid $secondary-color; } } }

  @each $icon in $iconListClients {
    i.#{$icon} {
      background-image: url('../img/icons/dashboard-company/#{$icon}.svg'); } } }
