.form-wrapper {
  padding: 1em 0; }

.form-title {
  display: block;
  width: auto;
  margin: 1em auto;
  text-transform: uppercase;
  text-align: center; }

label {
  font-weight: bold; }

fieldset {
  margin: 0.5em 0 1.5em 0;
  padding: 0 2rem 1rem 2rem;
  border: 1px solid $primary-color;

  legend {
    margin: 0;
    padding: 0 0.5rem;

    * {
      margin: 0; } }

  &.multilegs {
    .multileg {
      padding: 0 1em;
      margin-bottom: 1em;
      @include cardBoxShadow($cardBoxShadowColor); } } }

.form-group {
  @include clearfix;

  ~.form-item {
    margin: 1.5em 0 3em 0; }

  &.has-error {
    .form-item {
      margin: 1.5em 0 3.5em 0; } }

  .form-item {
    float: left;
    margin: 1.5em 0; }

  @for $i from 2 through 6 {
    &.items-#{$i} {
      .form-item {
        width: #{(100 / $i) - ($i / 2) + '%'};
        margin-right: #{(($i / 2) * $i / ($i - 1)) + '%'};

        &:nth-last-child(1) {
          margin-right: 0; } } } } }

.form-item {
  display: block;
  position: relative;
  margin: 3em 0;

  &.inline {
    >.form-item {
      display: inline-block;
      width: auto;
      margin: 0; }

    label {
      font-size: 1em;
      margin-right: 0.5em; } }

  &.hidden {
    display: none; }

  &.clearfix {
    overflow: hidden; }

  label.displaced {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0.5rem;
    text-transform: lowercase;
    font-size: 1em;
    line-height: 1.4;
    transition: all 150ms ease;
    z-index: 1;
    pointer-events: none;
    transition: all 0.25s;
    transform: translateY(0) scale(1);
    transform-origin: left top; }

  &:after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    margin: 0 50% 0 50%;
    bottom: -1px;
    left: 0;
    transition: all 500ms ease; }

  &.active {
    &:after {
      margin: 0 0 0 0;
      width: 100%; }

    label.displaced {
      transform: translateY(-65%) scale(0.75);
      padding: 0.5rem !important; } }

  .help-block {
    position: absolute;
    left: 0;
    top: 5.5em;
    padding-left: 0.5rem;
    font-size: 0.8em; }

  &.has-error {
    margin: 1.5em 0 3.5em 0; }

  &.has-icon {
    label.displaced {
      padding: 0.5rem 0.5rem 0.5rem 1.9rem; }

    i.mdi {
      display: block;
      position: absolute;
      top: 74%;
      left: 0;
      margin-top: calc(-2.4375rem / 2);
      width: 1.5em;
      height: 2.4375rem;
      text-align: center;
      line-height: 2.4375rem;
      transition: color 250ms ease; }

    input,
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    select,
    .chosen-single,
    .chosen-container-multi {
      padding-left: 1.9rem; } } }

input,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
textarea {
  margin: 0;

  &:focus {
    &::placeholder {
      opacity: 0; } }

  &::placeholder {
    opacity: 1;
    transition: opacity 500ms 250ms ease; } }

input[type="file"], .zurb-upload {
  padding: 0.5em 1em;
  border: 1px solid $primary-color; }


.form-item {
  &.fancy-radio {
    margin: 2em 0;

    input[type="checkbox"],
    input[type="radio"] {
      display: block;
      position: absolute;
      vertical-align: middle;
      width: 1.7em;
      height: 1.7em;
      font-size: 1.25em;
      visibility: hidden;
      opacity: 0;

      &+label {
        margin: 0;
        cursor: pointer;

        .mdi {
          display: inline-block;
          width: 1.7em;
          height: 1.7em;
          margin-right: 0.5em;
          text-align: center;
          line-height: 1.7em;
          font-size: 1.25em;
          border-radius: 50%;
          box-sizing: content-box;
          transition: 250ms ease; } } } }

  &.hidden-form {
    input[type="checkbox"],
    input[type="radio"] {
      &+label+.hidden-content {
        display: none; }

      &:checked {
        &+label+.hidden-content {
          display: block; } } } } }


textarea {
  min-height: auto;
  overflow: hidden; }

/*----------------------------Themes chosen plugin----------------------------*/
select.chosen-select {}

$chosen-sprite: image-url("chosen-sprite.png") !default;
$chosen-sprite-retina: image-url("chosen-sprite@2x.png") !default;

// @group Base
.chosen-container {
  @include form-select;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;

  * {
    box-sizing: border-box; }
  .chosen-drop {
    position: absolute;
    top: calc(100% + 2px);
    left: -9999px;
    z-index: 1010;
    width: 100%;
    border: 1px solid $primary-color;
    border-top: 0;
    background: #fff;
    box-shadow: 0 4px 5px rgba(black, 0.15); }
  &.chosen-with-drop .chosen-drop {
    left: 0; }
  a {
    cursor: pointer; }
  .search-choice, .chosen-single {
    .group-name {
      margin-right: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: normal;
      color: #999999;
      &:after {
        content: ":";
        padding-left: 2px;
        vertical-align: top; } } } }

// @end

// @group Single Chosen
.chosen-container-single {
  .chosen-single {
    @include form-select;
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    border-bottom: 1px solid $primary-color;
    background-color: transparent; }

  .chosen-default {
    color: #999; }
  .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .chosen-single-with-deselect span {
    margin-right: 38px; }
  .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    font-size: 1px;
    &:hover {
      background-position: -42px -10px; } }
  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px; }
  .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
    b {
      display: block;
      width: 100%;
      height: 100%; } }
  .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;

    &:after {
      font-family: 'FontAwesome';
      content: "\f002";
      display: block;
      position: absolute;
      top: 9px;
      right: 7px;
      color: $primary-color; }

    input[type="text"] {
      margin: 1px 0;
      padding: 4px 20px 4px 5px;
      width: 100%;
      height: auto;
      outline: 0;
      font-size: 1em;
      font-family: sans-serif;
      line-height: normal;
      border-radius: 0; } }
  .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box; }
  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px; } }

// @end

// @group Results
.chosen-container .chosen-results {
  color: #444;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
  li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    word-wrap: break-word;
    -webkit-touch-callout: none;
    &.active-result {
      display: list-item;
      cursor: pointer; }
    &.disabled-result {
      display: list-item;
      color: #ccc;
      cursor: default; }
    &.highlighted {
      background-color: #3875d7;
      background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
      color: #fff; }
    &.no-results {
      color: #777;
      display: list-item;
      background: #f4f4f4; }
    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default; }
    &.group-option {
      padding-left: 15px; }
    em {
      font-style: normal;
      text-decoration: underline; } } }

// @end

// @group Multi Chosen
.chosen-container-multi {
  @include form-select;
  position: relative;
  display: block;
  height: auto !important;
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 1px solid $primary-color;
  background-color: transparent;

  .chosen-choices {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    border: 0px none;
    background-color: #fff;
    cursor: text; }

  .chosen-choices li {
    float: left;
    list-style: none;
    &.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      input[type="text"] {
        margin: 1px 0;
        padding: 0;
        height: 25px;
        outline: 0;
        border: 0 !important;
        background: transparent !important;
        box-shadow: none;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border-radius: 0; } }

    &.search-choice {
      position: relative;
      margin: 3px 5px 3px 0;
      padding: 5px 22px 5px 9px;
      background-color: $primary-color;
      font-size: 0.9em;
      max-width: 100%;
      border-radius: 1em;
      color: #333;
      line-height: 13px;
      cursor: default;

      span {
        word-wrap: break-word;
        color: $white; }

      .search-choice-close {
        position: absolute;
        top: 6px;
        right: 4px;
        display: block;
        width: 12px;
        height: 12px;
        font-size: 0.8em;
        color: $white;

        &:before {
          font-family: "Material Design Icons";
          content: mdi('close'); } } }

    &.search-choice-disabled {
      padding-right: 5px;
      border: 1px solid #ccc;
      background-color: #e4e4e4;
      background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
      color: #666; }
    &.search-choice-focus {
      background: #d4d4d4;
      .search-choice-close {
        background-position: -42px -10px; } } }
  .chosen-results {
    margin: 0;
    padding: 0; }
  .chosen-drop .result-selected {
    display: list-item;
    color: #ccc;
    cursor: default; } }

// @end

// @group Active
.chosen-container-active {
  .chosen-single {
 }    //border: 1px solid #5897fb
  &.chosen-with-drop {
    .chosen-single {
 }      //box-shadow: 0 1px 0 #fff inset
    .chosen-single div {
      border-left: none;
      background: transparent;
      b {
        background-position: -18px 2px; } } }
  .chosen-choices {
    li.search-field input[type="text"] {
      color: #222 !important; } } }

// @end

// @group Disabled Support
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
  .chosen-single {
    cursor: default; }
  .chosen-choices .search-choice .search-choice-close {
    cursor: default; } }

// @end

// @group Right to Left
.chosen-rtl {
  text-align: right;
  .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0; }
  .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl; }
  .chosen-single-with-deselect span {
    margin-left: 38px; }
  .chosen-single div {
    right: auto;
    left: 3px; }
  .chosen-single abbr {
    right: auto;
    left: 26px; }
  .chosen-choices li {
    float: right;
    &.search-field input[type="text"] {
      direction: rtl; }
    &.search-choice {
      margin: 3px 5px 3px 0;
      padding: 3px 5px 3px 19px;
      .search-choice-close {
        right: auto;
        left: 4px; } } }
  &.chosen-container-single-nosearch .chosen-search,
  .chosen-drop {
    left: 9999px; }
  &.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0; }
  .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0; }
  &.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none; }
  .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: white $chosen-sprite no-repeat -30px -20px;
    background: $chosen-sprite no-repeat -30px -20px;
    direction: rtl; }
  &.chosen-container-single {
    .chosen-single div b {
      background-position: 6px 2px; }
    &.chosen-with-drop {
      .chosen-single div b {
        background-position: -12px 2px; } } } }

// @end

// @group Retina compatibility
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: $chosen-sprite-retina !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important; } }


/*-----------------Changes icon color on permissions context------------------*/
body.client,
body.not-logged-in {
  fieldset {
    border: 1px solid $primary-color;

    &.multilegs {
      .multileg {
        @include cardBoxShadow($cardBoxShadowColor); } } }

  label {
    color: $primary-color; }

  .form-title {
    color: $primary-color; }

  .form-item {
    >label {
      color: $light-gray; }

    &:after {
      background-color: darken($primary-color, 5); }

    //&.active
    ////>label
    //  //color: $black

    .help-block {
      color: $alert-color; }

    &.has-error {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="password"],
      select,
      textarea {
        border-bottom: 1px solid $alert-color; } }

    &.has-icon {
      i.mdi {
        color: $primary-color; }

      &.active {
        i.mdi {
          color: darken($primary-color,5); } } } }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  select,
  textarea {
    border-bottom: 1px solid $primary-color;

    &:focus {
      border-bottom: 1px solid $primary-color; } }

  input[type="file"], .zurb-upload {
    border: 1px solid $primary-color; }

  .form-item {
    &.fancy-radio {
      input[type="checkbox"],
      input[type="radio"] {
        &:checked {
          &+label .mdi {
            background-color: $primary-color;
            color: $white;
            border: 2px solid $primary-color; } }

        &+label {
          .mdi {
            background-color: transparent;
            color: $primary-color;
            border: 2px solid $primary-color; } } } } }

  // Chosen colors
  .chosen-container {
    .chosen-drop {
      border: 1px solid $primary-color;
      background: $white; }

    .chosen-search {
      &:after {
        color: $primary-color; } } }

  .chosen-container .chosen-results {
    color: #444; }

  .chosen-container-single {
    .chosen-single {
      border-bottom: 1px solid $primary-color; } } }

body.company,
body.admin,
body.superadmin {
  fieldset {
    border: 1px solid $secondary-color;

    &.multilegs {
      .multileg {
        @include cardBoxShadow($cardBoxShadowColorAdmin); } } }

  label {
    color: $secondary-color; }

  .form-title {
    color: $secondary-color; }

  .form-item {
    >label {
      color: mix($primary-color, $secondary-color, 35%); }

    &:after {
      background-color: darken($secondary-color, 5); }

    &.active {
      >label {
        color: $secondary-color; } }

    .help-block {
      color: darken($alert-color, 2); }

    &.has-error {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="password"],
      select,
      textarea {
        border-bottom: 1px solid darken($alert-color, 2); } }

    &.has-icon {
      i.mdi {
        color: $secondary-color; }

      &.active {
        i.mdi {
          color: darken($secondary-color,5); } } } }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  select,
  textarea {
    border-bottom: 1px solid $secondary-color;
    color: $white;

    &:focus {
      border-bottom: 1px solid $secondary-color; } }

  input[type="file"], .zurb-upload {
    border: 1px solid $secondary-color;
    color: $white; }

  .form-item {
    &.fancy-radio {
      input[type="checkbox"],
      input[type="radio"] {
        &:checked {
          &+label .mdi {
            background-color: $secondary-color;
            color: $white;
            border: 2px solid $secondary-color; } }

        &+label {
          .mdi {
            background-color: transparent;
            color: $secondary-color;
            border: 2px solid $secondary-color; } } } } }

  // Chosen colors
  .chosen-container {
    .chosen-drop {
      border: 1px solid $secondary-color;
      background: $medium-gray; }

    .chosen-search {
      &:after {
        color: $secondary-color; } } }

  .chosen-container .chosen-results {
    color: $white; }

  .chosen-container-single {
    .chosen-single {
      border-bottom: 1px solid $secondary-color;
      color: $white; } } }
