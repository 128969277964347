// Brincadeira xD --------------------------------------------------------------
::selection {
  background: $secondary-color; }

/*--------------------------------General CSS---------------------------------*/
body.not-logged-in,
body.logged-in.client {
  background-color: darken($white,5.5);

  main.content {
    background-color: darken($white,5.5); } }

body.logged-in.company {
  color: $white;
  font-weight: 300;
  background-color: $dark-gray;

  a {
    color: $secondary-color; }

  main.content {
    background-color: $dark-gray; } }

.container {
  display: block;
  width: 100%;
  padding: 5em 0; }

.universe {
  display: block;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  opacity: 1;
  transition: opacity 450ms;

  .content {
    //transform: translateX(0)
    //transition: transform 500ms cubic-bezier(0.91, 0.01, 0.6, 0.99)
    overflow: hidden; }

  &.js-menu-opened {
    .content {
      opacity: 0.6;
 } } }      //transform: translateX(350px)

main.content {
  opacity: 1;
  transition: opacity 300ms ease;

  &.faded {
    opacity: 0.5; } }

.card {
  padding: 0.1rem 0;
  margin-bottom: 0.5em; }

body.client,
body.not-logged-in {
    .card {
      @include cardBoxShadow ($cardBoxShadowColor);
      background-color: $white; } }
//----------------------------------Simulator----------------------------------
.simulator_results {
  font-size: 3em;
  text-align: center;

  .departure, .arrival {
    display: inline-block;
    font-weight: bold; }

  .passengers, .value {
    padding: 0.25em;
    font-size: 0.5em;
    color: $primary-color; } }

//----------------------------------Home page----------------------------------
.login {
  display: block;
  position: absolute;
  top: 0;
  right: 50px;
  min-width: 100px;
  padding: 0.5rem;
  background-color: $primary-color;
  color: $white !important;
  text-align: center;
  z-index: 9999;
  line-height: 1;

  .mdi {
    margin-bottom: 5px; } }

section {
  &.intro {
    background-image: url('../img/wing.jpg');
    background-size: cover;
    background-position: center;

    .logo {
      margin:  0 auto 2em; }

    .title {
      color: $primary-color; } } }

.sim {
  background:  linear-gradient(to bottom, rgba($primary-color, 0) 0%, rgba($primary-color, 1) 100%), url('../img/sky1.jpg');
  background-size: cover;
  background-position: center;

  .fp-tableCell {
    vertical-align: top; }

  .wrapper {
    padding-top: 20vh; }

  .title {
    color: $white;
    font-weight: bold; } }


//------------------------------Empty legs------------------------------------
.empty-legs {
  background-size: cover;
  background-position: center;

  .more {
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%); }

  .section-title {
    position: absolute;
    top: 1em;
    width: 100%;
    z-index: 2;
    text-align: center;
    color: $white; }

  .emptyleg__slide {
    padding: 0 1em;
    text-align: center;
    color: $white;
    cursor: pointer; }

  i.mdi-airplane-takeoff {
    padding: 0.51em;
    border: 3px solid $white;
    border-radius: 50%;
    font-size: 4em; }

  .destinos, .price {
    margin: 1em 0;
    text-transform: uppercase;
    font-weight: bold;

    span {
      text-transform: lowercase;
      font-weight: lighter; } } }

.slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  z-index: 2;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 4px;
    background-color: $white; }

  &:before {
    transform: rotate(45deg); }

  &:after {
    transform: rotate(-45deg); }


  &.slick-next {
    right: 0;

    &:before, &:after {
      transform-origin: right; }

    &:after {
      margin-top: -2px; } }

  &.slick-prev {
    left: 0;

    &:before, &:after {
      transform-origin: left; }

    &:before {
      margin-top: -2px; } } }


//----------------------Start Second Section------------------------------------
section.auctions {
  background-color: $white;
  color: $black;

  .section-title {
    position: absolute;
    top: 1em;
    width: 100%;
    z-index: 2;
    text-align: center;
    color: $primary-color; }

  .title {
    color: $primary-color;
    font-weight: 300; } }

//--------------------------------Section titles--------------------------------
.section-title {
  display: block;
  position: relative;
  min-width: 310px;
  margin: 0.5em auto;
  text-transform: uppercase; }


//------------------------------How it works------------------------------------
.how-it-works {
  background-color: $primary-color;

  .section-title {
    position: absolute;
    top: 1em;
    width: 100%;
    z-index: 2;
    text-align: center; }

  .num {
    font-weight: bold;
    font-size: 4em;
    display: block;
    border: 5px solid $white;
    border-radius: 50%;
    text-align: center;
    width: 2em;
    height: 2em;
    line-height: 1.9em;
    margin: 1rem auto;
    transition: all 700ms ease-in 250ms; }

  .title {
    text-align: center;
    font-weight: 300;
    transition: all 700ms ease-in 500ms; }

  .text {
    transition: all 700ms ease-in 750ms; }

  .fp-slide {
    .num {
      border: 5px solid darken($primary-color, 4);
      color: darken($primary-color, 4); }

    .title, .text {
      color: darken($primary-color, 4); }

    &.active {
      .num {
        border: 5px solid $white;
        color: $white; }

      .title, .text {
        color: $white; } } } }

/*-----------------------------------Benefits---------------------------------*/
section.benefits {
  display: block;
  position: relative;
  padding: 5em 0;
  color: $white;
  font-size: 1em;

  .section-title {
    width: 90%;
    text-align: center;
    margin-bottom: 2.5em; }

  .selectors {
    display: block;
    width: 55%;
    position: relative;
    margin: 0 0 0 auto; }

  .content {
    display: block;
    position: relative;
    width: 100%;
    height: 350px; }

  //Theme content items
  .content .item {
    display: block;
    position: absolute;
    min-width: 310px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    &:nth-child(odd) .icon:before {
      transform: rotate3d(-25, 15, 5, -180deg); }

    .title {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0ms linear 200ms, opacity 200ms linear; }

    .text {
      opacity: 0;
      visibility: hidden;
      transition: visibility 0ms linear 200ms, opacity 200ms linear; }

    .sp-icon {
      opacity: 0;
      transition: opacity 250ms ease-out 0ms; }

    &.active {
      .title {
        opacity: 1;
        visibility: visible;
        transition: visibility 0ms linear 0ms, opacity 400ms linear 350ms; }

      .text {
        opacity: 1;
        visibility: visible;
        transition: visibility 0ms linear 0ms, opacity 200ms linear 450ms; }

      .sp-icon {
        opacity: 1;
        transition: opacity 100ms ease-out 200ms;

        &:before {
          transform: rotate3d(0, 0, 0, 0deg); } } } }

  .sp-icon {
    position: absolute;
    display: block;
    top: -100px;
    width: 40px;
    height: 40px;
    padding: 1em;
    margin: 0 0 4em 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0px none;
    border-radius: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -30px;
      left: -30px;
      right: -30px;
      bottom: -30px;
      border: 2px solid $white;
      border-radius: 50%;
      transform: rotate3d(-25, 15, 35, 180deg);
      transition: transform 500ms ease-in; } }

  .title {
    display: block;
    margin: 1em 0;
    text-transform: uppercase; }

  .item.lorem,
  .item.loremtwo {
    .sp-icon {
      background-image: url('../img/cena.svg'); } }

  .item.loremone,
  .item.benefits {
    .sp-icon {
      background-image: url('../img/benefits.svg'); } }


  //Theme select items
  .selectors {
    text-align: center;

    .items {
      display: inline-block;
      text-align: left;

      &:hover {
        .item {
          opacity: 0.5;

          &:hover {
            opacity: 1 !important; }

          &.active {
            opacity: 0.75; } } } }

    .item {
      position: relative;
      cursor: pointer;
      transition: opacity 350ms linear;

      &:before, &:after {
        content: "";
        width: 1em;
        height: 2px;
        display: block;
        position: absolute;
        top: 0.82em;
        left: -2.5em;
        transition: opacity 200ms ease, transform 100ms ease-out; }

      &:before {
        background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 100%);
        opacity: 0;
        transform: translateX(-0.3em); }

      &:after {
        background: $white;
        opacity: 0;
        transform: translateX(-0.3em); }

      &:hover:before {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms ease, transform 300ms ease-out; }

      &.active {
        &:before, &:after {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 500ms ease, transform 300ms ease-out; } } } } }

/*---------------------------------Login Page---------------------------------*/
.user-login, .user-register, .user-reset-password {
  text-align: center;

  form {
    text-align: left; } }

/*------------------------ Multilegs button exception ------------------------*/
#multilegs {
  position: relative;
  padding-bottom: 5rem;
  padding-top: 1.5rem;

  #newmultileg {
    position: absolute;
    bottom: 0.5rem; } }

/*---------------------------- Helpdesk accordion ----------------------------*/
.helpdesk {
  .accordion {
    border: 0px none;
    background-color: transparent;

    .accordion-item {
      padding: 0.1rem;
      margin-bottom: 0.5rem;

      .accordion-title {
        border: 0px none;
        font-size: 0.8rem;
        font-weight: bold;
        transition: all 300ms;

        &:hover, &:focus {
          background-color: $primary-color;
          color: $white; } }

      .accordion-content {
        font-size: 0.75rem; }

      &.is-active {
        .accordion-title {
          &:hover, &:focus {
            background-color: $primary-color;
            color: $white; } } } } } }

body.not-logged-in,
body.logged-in.client {
  .helpdesk {
    .accordion {
      .accordion-item {
        .accordion-title {
          &:hover, &:focus {
            background-color: $primary-color;
            color: $white; } }

        &.is-active {
          .accordion-title {
            background-color: $primary-color;
            color: $white; } } } } } }

body.logged-in.company {
  .helpdesk {
    .accordion {
      .accordion-item {
        .accordion-title {
          color: $secondary-color;

          &:hover, &:focus {
            background-color: $secondary-color;
            color: $black; } }

        .accordion-content {
          color: $white; }

        &.is-active {
          .accordion-title {
            background-color: $secondary-color;
            color: $black; } } } } } }
