@charset 'utf-8';

@import 'settings';
@import 'components/variables';
@import 'foundation';
@import 'motion-ui';
@import "materialdesignicons";

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

// We target the mediascreens here, so you don't have to worry about them in each sass file.
//Small target's every screen (default)
@media screen {
	@import "styles/small";
	@import "C:/development/jetdeal/resources/assets/scss/components/buttons/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/dashboard/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/footer/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/forms/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/icons/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/notifications/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/offcanvas/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/pagination/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/tables/_small.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/tabs/_small.sass";
;
}
// 640px up
@media screen and #{breakpoint(medium)} {
	@import "styles/medium";
	@import "C:/development/jetdeal/resources/assets/scss/components/dashboard/_medium.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/footer/_medium.sass";
@import "C:/development/jetdeal/resources/assets/scss/components/offcanvas/_medium.sass";
;
}
// 1024px up
@media screen and #{breakpoint(large)} {
	@import "styles/large";
	@import "C:/development/jetdeal/resources/assets/scss/components/offcanvas/_large.sass";
;
}
// 1440px up
@media screen and #{breakpoint(xlarge)} {
	@import "styles/xlarge";
	;
}
// 1920px up
@media screen and #{breakpoint(xxlarge)} {
	@import "styles/xxlarge";
	;
}
