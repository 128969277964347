.topbar {
  display: block;
  position: fixed;
  width: 100%;
  padding: 0 0.5em;
  top: 0;
  left: 0;
  z-index: 3;

  .menu {
    display: block;
    float: right;

    .leaf {
      padding: 0 1em;

      a {
        width: 0;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        padding: 2rem 0rem;
        transition: width 250ms linear; }

      &:hover {
        a {
          width: 80px; } } } } }

main.dashboard {
  padding: 8em 0;

  .card {
    padding: 0.1rem 0;
    margin-bottom: 2em; }

  .panel-title {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 2px;
    text-transform: uppercase;
    text-align: center;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      left: 0;
      margin-top: -1px; }

    .title {
      display: inline-block;
      position: relative;
      padding: 1em;
      margin: 0; } }

  .sidebar {
    display: block;
    float: left;
    width: 30%;
    margin-right: 1.5%;
    padding: 0;

    li {
      position: relative;
      overflow: hidden;
      transition: all 250ms ease;

      a {
        font-weight: lighter; }

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        opacity: 0;
        transform: translateX(-5px);
        transition: all 250ms ease; }

      &.active {
        a {
          font-weight: 300; }

        i {
          padding: 10px;
          border-radius: 50%;
          font-size: 12px; } }

      &:hover {
        li {
          &:before {
            content: normal; }

          li {
            transform: translateX(-5px);

            &:hover {
              transform: translateX(0); } } }

        &:before {
          opacity: 1;
          transform: translateX(0); } } }


    .has-submenu.nano {
      max-height: 500px;
      transition: background-color 250ms linear;

      &[aria-expanded="false"] {
        background-color: rgba($black, 0); }

      &[aria-expanded="true"] {
        background-color: rgba($black, 0.025); } }

    header {
      padding: 2em 3em;

      i {
        margin-right: 0.5em; } }

    a {
      &:focus {
        outline: none; } } }

  .dashboard-content {
    display: block;
    float: left;
    width: 66%;
    margin-left: 1.5%; }

  .item {
    padding: 1em 2em;
    font-weight: 300;
    color: $light-gray;
    border-bottom: 1px solid $cardBoxShadowColor;

    &:nth-last-child(1) {
      border-bottom: 1px solid transparent; } } }

/*-------------------Themes specific color of the dashboard--------------------*/
body.client,
body.not-logged-in {
  .topbar {
    background-color: $white; }

  main.dashboard {
    .card {
      @include cardBoxShadow ($cardBoxShadowColor);
      background-color: $white; }

    .panel-title {
      color: $primary-color;

      &:before {
        background-color: $primary-color; } }

    .sidebar {
      header {
        color: $white;
        background-color: $primary-color;

        small {
          color: $white; } }

      >.menu {
 }        //background-color: darken($white, 4)

      a {
        color: $primary-color;

        &.active {
          color: $white;
          background-color: darken($primary-color, 5); } }

      li {
        &:before {
          background-color: $primary-color; }

        &.active {
          i {
            background-color: $primary-color;
            color: $white; } } } }

    .title {
      background-color: darken($white,5.5); } }

  .item {
    color: $light-gray; }

  .reverseauction.teaser:not(.bid),
  .broker.teaser {
    .accordion-title {
      @include cardBoxShadow ($cardBoxShadowColor);
      color: $primary-color;

      &:before {
        color: $primary-color; }

      &:after {
        background: linear-gradient(45deg, rgba($white,0) 3%,rgba($white,1) 25%,rgba($white,1) 100%); } }

    .countdown {
      color: $primary-color; }

    .accordion-content {
      .price {
        color: darken($primary-color, 5); } } }

  .reverseauction.bid, .reverseauction.createauction {
    header {
      .tabs-title {
        &:after {
          background-color: $primary-color; } }

      .info-wrapper {
        color: darken($primary-color, 15); } }

    .content-wrapper {
      .tabs-title {
        box-shadow: 0px 0px 2px rgba($black, 0);
        color: $light-gray;

        > a {
          background: transparent !important; }

        &:after {
          background-color: $white; }

        &.is-active {
          box-shadow: 0px 0px 2px rgba($black, 0.25);
          background-color: $white; } } } }

  #chatapp {
    .upper {
      border-bottom: 1px solid $cardBoxShadowColor; }

    .lower {
      button {
        &:hover {
          background-color: $primary-color;
          color: $white; } } }

    .messages {
      .message {
        &.mine {
          background-color: $primary-color;
          color: $white; }

        &.theirs {
          background-color: $cardBoxShadowColor;
          color: $black; } } } } }

body.company,
body.admin,
body.superadmin {
  background-color: $dark-gray;

  .topbar {
    background-color: $light-gray;

    a {
      color: $secondary-color; } }

  main.dashboard {
    .card {
      @include cardBoxShadow ($cardBoxShadowColorAdmin);
      background-color: $medium-gray; }

    .panel-title {
      color: $secondary-color;

      &:before {
        background-color: $secondary-color; } }

    .sidebar {
      header {
        color: $secondary-color;

        small {
          color: $secondary-color; } }

      >.menu {
        background-color: lighten($light-gray, 1); }

      a {
        color: $secondary-color; }

      li {
        &:before {
          background-color: $secondary-color; }

        &.active {
          i {
            background-color: $secondary-color;
            color: $light-gray; } } } }

    .title {
      background-color: $dark-gray; } }

  .reverseauction.teaser:not(.bid),
  .broker.teaser {
    .accordion-title {
      @include cardBoxShadow ($cardBoxShadowColorAdmin);
      color: $secondary-color;

      &:before {
        color: $secondary-color; }

      &:after {
        background: linear-gradient(45deg, rgba($medium-gray,0) 3%,rgba($medium-gray,1) 25%,rgba($medium-gray,1) 100%); } }

    .countdown {
      color: $secondary-color; }

    .accordion-content {
      small {
        color: $white; }

      .price {
        color: darken($secondary-color, 5); } } }

  .reverseauction.bid, .reverseauction.createauction {
    .airplane-img {
      border: 5px solid $secondary-color; }

    header {
      .tabs-title {
        &:after {
          background-color: $secondary-color; } }

      .info-wrapper {
        color: $white; } }

    .content-wrapper {
      .tabs-title {
        box-shadow: 0px 0px 2px rgba($black, 0);
        color: $light-gray;

        > a {
          background: transparent !important; }

        &:after {
          background-color: $medium-gray; }

        &.is-active {
          @include cardBoxShadow($cardBoxShadowColorAdmin);
          background-color: $medium-gray; } } } }

  .item {
    position: relative;
    color: $white;

    .post {
      margin-top: 2.5em;
      margin-bottom: 5em;

      .title, .body {
        padding: 1em;
        margin-bottom: 2px;
        background-color: rgba($black, 0.1);

        h4, h5, h6 {
          display: inline;
          color: $secondary-color; } } } }

  .preview {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 11px;
    left: 15px;
    background-image: url('../img/icons/dashboard-company/eye.png');
    background-size: contain;

    &:hover {
      img {
        display: block; } }

    img {
      display: none;
      position: absolute;
      top: 8px;
      left: 8px;
      max-width: 520px;
      @include cardBoxShadow($cardBoxShadowColorAdmin); } }

  #chatapp {
    .upper {
      border-bottom: 1px solid $cardBoxShadowColorAdmin; }

    .lower {
      button {
        &:hover {
          background-color: $secondary-color;
          color: $white; } } }

    .messages {
      .message {
        &.mine {
          background-color: $secondary-color;
          color: $black; }

        &.theirs {
          background-color: $cardBoxShadowColor;
          color: $white; } } } } }

/*------------------Themes the teaser of the reverse Auction------------------*/
.reverseauction.accordion {
  background-color: transparent; }

.reverseauction.teaser:not(.bid),
.broker.teaser:not(.bid) {
  max-height: 3.75em;
  margin-bottom: 0.5em;
  padding: 0;
  transition: max-height 750ms ease, transform 450ms ease, opacity 450ms ease;
  overflow: hidden;

  &.is-active {
    max-height: 30em;

    .accordion-title {
      background-color: transparent;

      &:after {
        transform: translateX(-5%); }

      &:before {
        content: mdi('arrow-compress-all'); }

      > span {
        transform: translateX(30%); } } }

  &.ng-leave {
    transition: max-height 200ms ease 200ms, margin-bottom 250ms ease 200ms, transform 300ms ease, opacity 200ms ease;
    transform: translateX(0);
    opacity: 1;
    max-height: 3.75em;
    margin-bottom: 0.5em;

    &.ng-leave-active {
      transform: translateX(10%);
      opacity: 0;
      max-height: 0;
      margin-bottom: 0; } }

  &.ng-enter {
    transition: max-height 250ms ease 200ms, margin-bottom 250ms ease 200ms, transform 350ms ease 450ms, opacity 350ms ease 450ms;
    transform: translateX(10%);
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;

    &.ng-enter-active {
      transform: translateX(0);
      opacity: 1;
      max-height: 3.75em;
      margin-bottom: 0.5em; } }

  .accordion-title {
    position: relative;
    overflow: hidden;
    font-size: 0.9rem;
    @include cardBoxShadow ($cardBoxShadowColor);

    > span {
      display: inline-block;
      position: relative;
      width: 48%;
      z-index: 2;
      transition: 250ms ease-out;
      transform: translateX(0); }

    &:hover, &:focus {
      background-color: transparent;

      &:after {
        transform: translateX(-5%); }

      > span {
        transform: translateX(30%); } }

    &:before {
      font-family: "Material Design Icons";
      content: mdi('arrow-expand-all');
      z-index: 3;
      margin-top: -0.45em; }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(255,255,255,0) 3%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 100%);
      transform: translateX(-25%);
      transition: 350ms ease-out; }

    .background {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: auto 85px !important;
      background-position: 0 50% !important;
      background-repeat: no-repeat !important; } }

  .details-preview {
    display: inline-block;
    vertical-align: middle; }

  .countdown {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 48%;
    z-index: 2;
    color: $primary-color;
    text-align: right; }

  .accordion-content {
    display: block;
    padding: 0;

    .item {
      padding: 1rem 0;
      font-size: 0.9em; }

    .price-links {
      padding-top: 1rem; }

    .price {
      color: darken($primary-color, 5); }

    .button {
      font-size: 0.75em; } }

  i.success {
    color: $success-color; }

  i.alert {
    color: $alert-color; }

  i.warning {
    color: $warning-color; } }

.broker.teaser:not(.bid) {
  &.is-active {
    max-height: 65em; } }

/*  display: block
  position: relative
  width: 100%
  height: 500px
  max-width: 300px
  margin: 0 0 map-get($grid-column-responsive-gutter, small) 0
  text-align: center
  background-size: cover
  background-position: 17% center
  background-repeat: no-repeat
  color: $white

  &:before
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba($black, 0.5)

  .bottom-align
    position: absolute
    width: 100%
    bottom: 0
    left: 0

  .airports
    text-transform: uppercase
    font-weight: bold
    margin: 1em 0

  .countdown
    margin: 1em 0*/

/*-----------------------Themes the reverse Auction Bid-----------------------*/
.reverseauction.bid, .reverseauction.createauction {
  header {
    margin-bottom: 1em;

    .tabs-title {
      position: relative;
      overflow: hidden;
      text-transform: uppercase;
      font-weight: bold;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3px;
        height: 3px;
        opacity: 0;
        transform: translateY(0);
        transition: all 250ms ease; }

      &.is-active {
        &:after {
          opacity: 1;
          transform: translateY(-3px); } } } }

  .content-wrapper {
    width: 100%;
    float: left;

    .tabs-panel {
      padding: 0; }

    .tabs-title {
      position: relative;
      text-transform: uppercase;
      padding: 1rem 0.5em;
      font-weight: thin;
      transition: all 250ms ease;
      background-color: transparent;
      text-align: center;

      > a {
        background: transparent !important; }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3px;
        height: 6px;
        opacity: 0;
        transition: all 250ms ease; }

      &.is-active {
        &:after {
          opacity: 1; } } }

    .info-preview {
      display: block;
      width: 100%;
      min-height: 44px;
      font-size: 3em;

      i.icon {
        margin: 0;
        font-size: 0.7em; } } }

  .airplane-img {
    display: inline-block;
    width: 110px;
    height: 110px;
    margin: 1em 25px 1em 0;
    border-radius: 50%;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

  .info-wrapper {
    display: inline-block;
    width: calc(100% - 140px);
    vertical-align: middle; }

  .bid-number {
    text-transform: uppercase;
    color: $white; }

  .airplane-model,
  .company-nationality {
    margin: 0; }

  .company-avatar {
    @extend .airplane-img; } }

.reverseauction.createauction {
  .form-wrapper {
    padding: 3em 0; } }


/*----------------Themes the teaser of the reverse Auction Bid----------------*/
.reverseauction.teaser.bid, .airplane.teaser {
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 0 map-get($grid-column-responsive-gutter, small) 0;
  padding: 1em 1em 4em 1em !important;
  text-align: center;

  &.accepted {
    &:before {
      font-family: 'FontAwesome';
      content: "\f00c";
      display: block;
      position: absolute;
      top: 3px;
      left: 10px;
      font-size: 1.5em;
      color: $success-color; } }

  .airplane-img {
    display: inline-block;
    width: 140px;
    height: 140px;
    margin: 1.5em 0;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

  .bid-number {
    text-transform: uppercase;
    color: darken($white, 25); }

  .airplane-model,
  .company-nationality {
    color: darken($white, 25); }

  .price {
    display: block;
    margin-top: 4em;
    text-align: right;
    color: $primary-color; }

  .button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; } }

.airplane.teaser {
  .airplane-img {
    border: 5px solid $secondary-color; } }

/*------------------------Themes create Auction page -------------------------*/
.reverseauction.createauction {
  text-align: center;

  form {
    text-align: left; } }

/*------------------------------------Chat------------------------------------*/
#chatapp {
  .upper {
    display: block;
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden; }

  .lower {
    position: relative;
    width: 100%;

    .attach {
      position: absolute;
      top: -5px;
      left: 15px;
      font-size: 2em;
      color: $primary-color; }

    input[type="text"] {
      width: calc(100% - 150px);
      float: left;
      border: 0px none;
      padding-left: 60px; }

    button {
      height: 2.4375rem;
      margin: 0;
      font-weight: bold;
      padding: 0.5rem;
      width: 150px;
      border-radius: 0;
      border: 0px; } }

  .messages {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 0.5rem;
    list-style: none;

    .message {
      display: block;
      max-width: 350px;
      padding: 0.5rem 1.5rem;
      border-radius: 35px;
      transform: translateX(0);
      transition: transform 250ms ease, border-radius 250ms ease-in 150ms;

      &.hidden {
        border-radius: 15px; }

      &.mine {
        margin: 0.5rem 0 0.5rem auto;
        border-bottom-right-radius: 3px;

        &.hidden {
          transform: translateX(120%); } }

      &.theirs {
        margin: 0.5rem auto 0.5rem 0;
        border-bottom-left-radius: 3px;

        &.hidden {
          transform: translateX(-120%); } } } } }

.chat-inactive {
  opacity: 0.6;

  > * {
    pointer-events: none; }

  .message {
    visibility: hidden; } }
